
import img1 from '../../img/partner/logo/aes.png';
import img2 from '../../img/partner/logo/namaskar.png';
import img3 from '../../img/partner/logo/Tanksandcover.png';
import img4 from '../../img/partner/logo/psyche.png';
import img5 from '../../img/partner/logo/lpm.png';
import img6 from "../../img/partner/logo/softside.png";

const TeamData = [
    {
        id:1,
        img:img1
    },{
        id:2,
        img:img2
    },{
        id:3,
        img:img3
    },{
        id:4,
        img:img4
    },{
        id:5,
        img:img5
    },{
        id:6,
        img:img6
    },
    
]

export default TeamData;